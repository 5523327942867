:root {
  --primary: rgb(8 109 195);
  --primary-foreground: rgb(255 255 255);

  --Able-blue: #06579c;
  --Able-grey: #3f4040;
  --Able-yellow: #ffc107;

  --Able-blue-pastel: #194abd;
  --Able-blue-dark: #131f89;
  --Text-strongest: #373737;
  --Text-strong: #585b5d;
  --Text-light: #c5c5c5;
  --Text-lightest: #dee3e7;
  --Rusty-red: #c03830;
  --Sea-grey: #6c879e;
  --Button-light: #238bd0;
  --Confirm-hovered: #087125;
  --Confirm: #0ca034;
  --Confirm-light: #4ccf6f;
  --Able-yellow-dark: #c99702;
  --Able-yellow-muted: #e8cb74;
  --Able-yellow-bright: #f0e158;

  --ss-desktop: 1024px;
  --ss-tablet: 768px;
  --ss-mobile: 450px;
}
body {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
/* #editor .ace_content *{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
} */
.ace_content {
  font-family: "Fira Code" !important;
  /* font-family: 'Courier New', Courier, monospace !important; */
  letter-spacing: .075px;
}
.App {
  text-align: center;
  /* font-family: 'Courier New', Courier, monospace !important; */
}
.Test {
  font-family: "Fira Code", 'Courier New', Courier, monospace !important;
  /* font-family: 'Courier New', Courier, monospace !important; */
  letter-spacing: .075px;
}
#div.view_line {
  font-family: "Fira Code", monospace !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-p27qcy[aria-checked=true], .css-p27qcy[data-checked]{
  --switch-bg: var(--Able-yellow) !important
}
.css-p27qcy{
  --switch-bg: var(--Able-blue) !important
}
.css-7roig[aria-checked=true], .css-7roig[data-checked] {
  -webkit-transform: translateX(var(--switch-thumb-x));
  -moz-transform: translateX(var(--switch-thumb-x));
  -ms-transform: translateX(var(--switch-thumb-x));
  transform: translateX(var(--switch-thumb-x));
  --switch-bg: whitesmoke !important;
  background: var(--chakra-colors-backgroundDarkGrey)
}

.css-7roig {
  background: var(--chakra-colors-backgroundDarkGrey) !important
}

.css-mxa4qn{
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important ;

}
.css-9fgtzh {
  background-color: var(--chakra-colors-AbleBlue) !important;
  border-radius: 0.3rem;
  color: white !important;
  border-color: var(--chakra-colors-AbleBlue);
  border-bottom-color: var(--chakra-colors-AbleYellow);
  border-width: 3px;
}
.css-qlig70 {
  background-color: var(--chakra-colors-backgroundDarkGrey) !important;
}
.css-k0waxj {
  background-color: var(--chakra-colors-backgroundDarkGrey) !important;
}
body.chakra-ui-light {
  margin-right: 0px !important;
}

iframe#webpack-dev-server-client-overlay{display:none!important}
