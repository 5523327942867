i {
    font-size: 40px;
}

.icn-spinner {
    animation: spin-animation .75s infinite;
    animation-timing-function: linear;
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
