body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--chakra-colors-backgroundDarkGrey) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.noneModal {
  min-height: 400px !important;
  overflow: hidden !important;
  transition: min-height 1s linear;
}
.inputModal{
  min-height: 100px !important;
  transition: min-height 0.6s ease-in-out;
}
.longerModal{
  min-height: 400px !important;
  transition: min-height 3s linear;
}