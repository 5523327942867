.react-ios-time-picker-input {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #0005;
    text-align: center;
    width: 65px;
}

.time-box-style2{
    color: lightgrey;
    pointer-events: none;
}
.time-box-style{
    text-decoration-color: blue;
}
.time-popup-style {
    position:  relative !important;
    margin-bottom: 50%;
    padding-bottom: 100px;
    bottom: 10;
}
.react-ios-time-picker-popup {
    padding-bottom: 50vh;
    background-color: rgba(0, 0, 0, 0.5);
}