/* * {
    font-family: 'Cascadia Code', monospace;
    
  } */
  .code-editor-dialog-container {
    padding-right: 3rem ;
  }
  .test{
    display: flex;
    flex-direction: row;
  
  }
  .span-test{
    size: 1000px;
    padding-left: 10%;
  }
  .test-p{
    padding-left: 100%;
  }
  .ace_content * {
    font-family: 'Cascadia Code', monospace !important;
  }
  .blink {
    color: #fff;
    
    animation: blinkingBackground 2s;
}

@keyframes blinkingBackground {
    0% {
        background-color: rgba(255, 153, 0, 0);
    }

    50% {
        background-color: rgb(176, 0, 0);
    }

    100% {
        background-color: rgba(255, 153, 0, 0);
    }
}